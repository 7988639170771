@media (min-width: 1440px) {
    .img-sitio {
        right: 150px !important;
    }
}

@media (min-width: 735px) {
    .img-sitio {
        right: 300px !important;
    }
}

@media (min-width: 325px) {
    .img-sitio {
        right: -20px !important;
    }

    .icon-sitio {
        bottom: -20px !important;
    }
}
