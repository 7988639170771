.sections-cotizador {
    display: flex;
    width: 100%;
    justify-content: center;
}

#animacion5 {
    width: 250px;
    height: 550px;
}

.container-animation-cotizador {
    display: flex;
    width: 100%;
}
  
.hidden-left-cotizador {
    width: 15%;
}
  
.hidden-right-cotizador {
    width: 85%;
}
  
/* Left */
  
@media(prefers-reduced-motion) {
    .hidden-left-cotizador {
      transition: none;
    }
}
  
.hidden-left-cotizador {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}
  
/* Right */
  
@media(prefers-reduced-motion) {
    .hidden-right-cotizador {
      transition: none;
    }
}
  
.hidden-right-cotizador {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(0%);
    transition: all 1s;
}
  
/* Show */
  
.show-cotizador {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}
  
@media (max-width: 880px) {
    .hidden-left-cotizador {
        display: none;
    }
}