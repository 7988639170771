.sections-nuevozapopan {
    display: flex;
    width: 100%;
}

#animacion3 {
    width: 100px;
    height: 150px;
}
  
.hidden-left-nuevozapopan {
    width: 10%;
}
  
.hidden-right-nuevozapopan {
    width: 90%;
}
  
/* Left */
  
@media(prefers-reduced-motion) {
    .hidden-left-nuevozapopan {
      transition: none;
    }
}
  
.hidden-left-nuevozapopan {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-20%);
    transition: all 1s;
}
  
/* Right */
  
@media(prefers-reduced-motion) {
    .hidden-right-nuevozapopan {
      transition: none;
    }
}
  
.hidden-right-nuevozapopan {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(0%);
    transition: all 1s;
}
  
/* Show */
  
.show-nuevozapopan {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}
  
@media (max-width: 880px) {
    .hidden-left-nuevozapopan {
        display: none;
    }

    .hidden-right-nuevozapopan {
        width: 100%;
    }
}